html {
    height: 100%;
}

body{
    margin: 0;
}

#main{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof{
	  display: table-cell;
	  vertical-align: middle;
      font-family: 'Lato', sans-serif;
      color: #888;
}

.fof h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
}